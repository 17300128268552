import React, { useState } from "react"
import { isBefore } from "date-fns"
import { useStyletron } from "baseui"
import type { CalendarProps } from "baseui/datepicker"

import AvailabilityCalendar from "~components/pages/listing/availability-calendar"
import { getUserReadableDate } from "~utils/date-helpers"
import COPY_CONSTANTS from "~config/copy-constants"

import { handleSearch } from "../search-helpers"
import { TEST_IDS } from "../../../../config/test-ids"

import ClearButton from "./clear-button"
import Footer from "./footer"
import BackButton from "./back-button"
import SearchView from "./search-view"
import SearchTile from "./search-tile"
import CardTitle from "./card-title"

interface Props {
    readonly query?: string
    readonly dates?: Date[]
    readonly closeDrawer: () => void
}

export default function MobileMenu({ query, dates, closeDrawer }: Props) {
    const styles = useStyles()
    const [input, setInput] = useState<string>(query || "")
    const [selectedDates, setSelectedDates] = useState<Date[]>(dates || [])
    const [activeStep, setActiveStep] = useState<number>(0)
    const [isSearching, setIsSearching] = useState<boolean>(false)

    function resetValues() {
        setInput("")
        setSelectedDates([])
        setActiveStep(0)
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        event.stopPropagation()
        handleSearch(input, selectedDates)
        closeDrawer()
    }

    function handleCalendarChange(newDate: { date: Date[] | Date }) {
        onCalendarChange({
            newDate,
            selectedDates,
            setSelectedDates,
        })
    }

    return (
        <div className={styles.root} data-testid={TEST_IDS.SEARCH_MOBILE_MENU}>
            <BackButton
                isSearching={isSearching}
                onClick={() => {
                    if (isSearching) {
                        setIsSearching(false)
                    } else {
                        closeDrawer()
                    }
                }}
            />
            {isSearching ? (
                <SearchView
                    input={input}
                    setInput={setInput}
                    setIsSearching={setIsSearching}
                    setActiveStep={setActiveStep}
                />
            ) : (
                <div className={styles.list}>
                    <div
                        className={styles.card}
                        onClick={() => setActiveStep(0)}
                        data-testid={TEST_IDS.MOBILE_SEARCH_CARD}
                    >
                        <SearchTile
                            isActive={activeStep === 0}
                            input={input}
                            setInput={setInput}
                            setIsSearching={setIsSearching}
                        />
                    </div>
                    <div
                        className={styles.card}
                        onClick={() => setActiveStep(1)}
                    >
                        <CardTitle
                            title="When"
                            isActive={activeStep === 1}
                            activeComponent={
                                selectedDates.length > 0 && (
                                    <ClearButton
                                        onClick={() => setSelectedDates([])}
                                    />
                                )
                            }
                            inactiveComponent={
                                <p className={styles.cardHelpText}>
                                    {getDatesHelperText(selectedDates)}
                                </p>
                            }
                        />
                        {activeStep === 1 && (
                            <AvailabilityCalendar
                                minDate={new Date()}
                                includeDates={[]}
                                value={selectedDates}
                                onChange={
                                    handleCalendarChange as CalendarProps<Date>["onChange"]
                                }
                            />
                        )}
                    </div>
                </div>
            )}
            <form
                onSubmit={handleSubmit}
                data-testid={TEST_IDS.MOBILE_SEARCH_FORM}
            >
                <Footer resetValues={resetValues} />
            </form>
        </div>
    )
}

interface OnCalendarChangeProps {
    newDate: { date: Date[] | Date }
    selectedDates: Date[]
    setSelectedDates: (dates: Date[]) => void
}

function onCalendarChange({
    newDate,
    selectedDates,
    setSelectedDates,
}: OnCalendarChangeProps) {
    if (Array.isArray(newDate.date)) {
        if (!selectedDates[0]) {
            setSelectedDates(newDate.date)
        } else if (
            newDate.date[0] &&
            isBefore(newDate.date[0], selectedDates[0])
        ) {
            setSelectedDates(newDate.date)
        } else if (selectedDates.length <= 2) {
            setSelectedDates(newDate.date)
        } else {
            setSelectedDates([selectedDates[0], ...newDate.date])
        }
    } else {
        setSelectedDates([newDate.date])
    }
}

function getDatesHelperText(selectedDates: Date[]) {
    if (!selectedDates[0]) {
        return COPY_CONSTANTS.ADD_DATES
    }

    const firstDate = getUserReadableDate(selectedDates[0])

    return `${firstDate}${getSecondDateHelperText(selectedDates)}`
}

function getSecondDateHelperText(selectedDates: Date[]) {
    if (
        !selectedDates[1] ||
        selectedDates[1].getTime() === selectedDates[0]?.getTime()
    ) {
        return ""
    }

    return `- ${getUserReadableDate(selectedDates[1])}`
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        root: css({
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            position: "relative",
            backgroundColor: theme.colors.backgroundSecondary,
        }),
        list: css({
            flex: 1,
            paddingTop: theme.sizing.scale400,
            paddingBottom: theme.sizing.scale400,
            paddingLeft: theme.sizing.scale400,
            paddingRight: theme.sizing.scale400,
        }),
        card: css({
            display: "flex",
            overflow: "hidden",
            flexDirection: "column",
            backgroundColor: theme.colors.backgroundPrimary,
            borderTopLeftRadius: theme.sizing.scale600,
            borderTopRightRadius: theme.sizing.scale600,
            borderBottomLeftRadius: theme.sizing.scale600,
            borderBottomRightRadius: theme.sizing.scale600,
            boxShadow: theme.lighting.shadow400,
            marginBottom: theme.sizing.scale600,
        }),
        cardHelpText: css({
            fontSize: "0.9rem",
            fontWeight: "600",
        }),
    }
}
